import React from "react";
import { styled } from "@mui/system";

const InstagramIcon = () => {
  return (
    <>
      <Svg version="1.1" width="1.7em" height="1.7em" viewBox="0 0 512 512">
        <path d="m392 512h-272c-66.168 0-120-53.832-120-120v-272c0-66.168 53.832-120 120-120h272c66.168 0 120 53.832 120 120v272c0 66.168-53.832 120-120 120zm-272-472c-44.112 0-80 35.888-80 80v272c0 44.112 35.888 80 80 80h272c44.112 0 80-35.888 80-80v-272c0-44.112-35.888-80-80-80zm277 50c-13.807 0-25 11.193-25 25 0 13.807 11.193 25 25 25 13.807 0 25-11.193 25-25 0-13.807-11.193-25-25-25zm-141 296c-71.683 0-130-58.317-130-130 7.14-172.463 252.886-172.413 260 .001 0 71.682-58.317 129.999-130 129.999zm0-220c-49.626 0-90 40.374-90 90 4.944 119.397 175.074 119.362 180-.001 0-49.625-40.374-89.999-90-89.999z" />
      </Svg>
    </>
  );
};

const Svg = styled("svg")({
  path: {
    fill: "#ffffff",
  },
});

export default InstagramIcon;

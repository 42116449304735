import React from "react";
import { styled } from '@mui/system';

const TwitterIcon = () => {
  return (
    <>
      <Svg version="1.1" width="1.7em" height="1.7em" viewBox="0 0 32 32">
        <g id="Outline_Color_copy_2">
          <g>
            <path d="M28,1c1.654,0,3,1.346,3,3v24c0,1.654-1.346,3-3,3H4c-1.654,0-3-1.346-3-3V4c0-1.654,1.346-3,3-3H28 M28,0H4    C1.8,0,0,1.8,0,4v24c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V4C32,1.8,30.2,0,28,0L28,0z" />
          </g>
          <path
            d="M18.328,8.56c-1.663,0.605-2.714,2.166-2.594,3.874l0.04,0.659l-0.665-0.081   c-2.421-0.309-4.537-1.358-6.333-3.121L7.897,9.017L7.671,9.663c-0.479,1.439-0.173,2.96,0.825,3.982   c0.532,0.565,0.412,0.646-0.505,0.309c-0.319-0.107-0.599-0.188-0.625-0.148c-0.093,0.095,0.226,1.318,0.479,1.803   c0.346,0.673,1.051,1.331,1.823,1.722l0.652,0.309l-0.771,0.013c-0.745,0-0.771,0.013-0.691,0.297   c0.266,0.874,1.317,1.803,2.488,2.206l0.825,0.282l-0.718,0.431c-1.064,0.62-2.315,0.969-3.566,0.995   c-0.599,0.013-1.091,0.067-1.091,0.108c0,0.134,1.624,0.887,2.568,1.184c2.834,0.874,6.2,0.497,8.728-0.996   c1.796-1.063,3.592-3.175,4.431-5.22c0.453-1.089,0.905-3.08,0.905-4.034c0-0.619,0.04-0.7,0.785-1.439   c0.439-0.43,0.851-0.901,0.931-1.036c0.133-0.256,0.119-0.256-0.559-0.027c-1.131,0.404-1.291,0.35-0.731-0.255   c0.412-0.43,0.905-1.211,0.905-1.439c0-0.04-0.199,0.027-0.426,0.148c-0.239,0.135-0.771,0.337-1.171,0.457L22.44,9.543   l-0.652-0.444c-0.359-0.242-0.864-0.511-1.131-0.592C19.978,8.318,18.94,8.345,18.328,8.56z"
            fill="#0BA4E0"
          />
        </g>
      </Svg>
    </>
  );
};

const Svg = styled("svg")({
  path: {
    fill: "#ffffff",
  },
});

export default TwitterIcon;

import React from "react";

// MATERIAL UI
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

import InstagramIcon from "assets/InstagramIcon";
import LinkedinIcon from "assets/LinkedinIcon";
import TwitterIcon from "assets/TwitterIcon";
import DribbbleIcon from "assets/DribbbleIcon";

const Collaborator = ({ collabData }) => {
  return (
    <Container>
      <CollabImage src={collabData.image} alt="collaborator bookmark manager" />
      <DataContainer>
        <Typography variant="h4" sx={{ mt: 0 }}>
          {collabData.name}
        </Typography>
        <Typography variant="label">{collabData.responsibleFor}</Typography>
        <Typography sx={{ flex: 0.9 }}>{collabData.description}</Typography>
        <Social>
          {collabData.instagram && (
            <a href={collabData.instagram} target="_blank" rel="noreferrer">
              <InstagramIcon />
            </a>
          )}
          {collabData.linkedin && (
            <a href={collabData.linkedin} target="_blank" rel="noreferrer">
              <LinkedinIcon />
            </a>
          )}
          {collabData.twitter && (
            <a href={collabData.twitter} target="_blank" rel="noreferrer">
              <TwitterIcon />
            </a>
          )}
          {collabData.dribbble && (
            <a href={collabData.dribbble} target="_blank" rel="noreferrer">
              <DribbbleIcon />
            </a>
          )}
        </Social>
      </DataContainer>
    </Container>
  );
};

const Container = styled("div")({
  display: "flex",
  width: "100%",
  height: "400px",
  marginBottom: "120px",

  "@media (max-width: 830px)": {
    flexDirection: "column",
    height: "auto",
  },
});

const CollabImage = styled("div")(({ src }) => ({
  width: "320px",
  height: "100%",
  borderRadius: "14px",
  backgroundSize: "cover",
  backgroundPosition: "top",
  backgroundImage: `url(${src})`,

  "@media (max-width: 992px)": {
    width: 250,
  },

  "@media (max-width: 830px)": {
    height: 300,
    margin: "auto",
  },
}));

const DataContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 40,
  height: "100%",
  width: "calc(100% - 320px)",

  "@media (max-width: 830px)": {
    paddingLeft: 0,
    width: "100%",
  },
});

const Social = styled("div")({
  svg: {
    marginRight: 16,
  },
});

export default Collaborator;

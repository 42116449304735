import React from "react";
import { styled } from "@mui/system";

const DribbbleIcon = () => {
  return (
    <>
      <Svg
        version="1.1"
        width="1.7em"
        height="1.7em"
        viewBox="0 0 96.954 96.954"
      >
        <g>
          <path
            d="M48.477,0.065C21.747,0.066,0.001,21.782,0,48.477c0.001,26.695,21.747,48.412,48.477,48.412s48.476-21.717,48.477-48.412
		C96.953,21.782,75.207,0.066,48.477,0.065z M80.372,22.917c5.528,6.851,8.892,15.508,9.048,24.934
		c-1.84-0.372-9.634-1.83-18.947-1.83c-3.004,0-6.166,0.153-9.336,0.542c-0.267-0.648-0.537-1.3-0.816-1.954
		c-0.824-1.934-1.707-3.852-2.627-5.739C72.023,32.965,78.941,24.77,80.372,22.917z M48.477,7.641
		c10.328,0,19.77,3.839,26.982,10.161c-1.143,1.559-7.333,9.294-21.152,14.52C47.912,20.625,40.907,10.946,39.241,8.697
		C42.211,8.008,45.301,7.641,48.477,7.641z M30.885,11.615c1.412,1.942,8.422,11.725,14.982,23.329
		c-17.606,4.626-33.242,4.93-37.014,4.93c-0.154,0-0.287,0-0.4,0C11.162,27.379,19.606,16.996,30.885,11.615z M7.522,48.54
		c0-0.336,0.008-0.673,0.018-1.01c0.245,0.004,0.604,0.008,1.074,0.008c5.08,0,22.722-0.423,40.901-5.819
		c1.103,2.16,2.155,4.35,3.129,6.538c-0.455,0.132-0.91,0.266-1.36,0.411C30.817,55.28,19.563,72.78,17.833,75.639
		C11.421,68.415,7.522,58.926,7.522,48.54z M48.477,89.44c-9.397,0-18.06-3.182-24.979-8.515
		c1.169-2.294,9.659-17.584,32.092-25.392c0.016-0.005,0.032-0.011,0.047-0.017c5.621,14.646,8.004,26.937,8.661,30.744
		C59.428,88.307,54.082,89.44,48.477,89.44z M71.671,82.229c-0.549-3.15-2.755-14.67-7.85-28.719
		c2.858-0.442,5.639-0.617,8.245-0.617c8.559-0.001,15.255,1.873,16.811,2.346C87.027,66.4,80.639,76.051,71.671,82.229z"
          />
        </g>
      </Svg>
    </>
  );
};

const Svg = styled("svg")({
  path: {
    fill: "#ffffff",
  },
});

export default DribbbleIcon;

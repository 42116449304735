import React from "react";

// MATERIAL UI
import { Typography } from "@mui/material";

// COMPONENTS
import Layout from "components/Layout";
import Collaborator from "components/Collaborator";

// Images
import VictorFound from "assets/images/victor.png";
import IvanCollab from "assets/images/ivan.png";

const BehindPauPage = () => {
  return (
    <Layout pageTitle="Behind Pau" metaTitle="Meet the people behind Pau">
      <Typography variant="h3" sx={{ mb: 4, textAlign: "center" }}>
        Contributors
      </Typography>
      {collabs.map((c) => {
        return <Collaborator collabData={c} />;
      })}

      <Typography variant="h3" sx={{ mb: 4, textAlign: "center" }}>
        Founders
      </Typography>
      {founders.map((c) => {
        return <Collaborator collabData={c} />;
      })}
    </Layout>
  );
};

const collabs = [
  {
    name: "Ivan Salaya",
    responsibleFor: "Logo",
    instagram: "https://instagram.com/the.communion",
    dribbble: "https://dribbble.com/communionstd",
    linkedin: "https://www.linkedin.com/in/ivan-salaya/",
    description:
      "Hi there! My name is Iván Salaya. I'm a motion designer and Founder of The Communion Std. a visual Studio based in Mérida. I love to work on awesome projects like Pau, because I learn new things while helping people get their ideas off the ground through good design.",
    image: IvanCollab,
  },
];

const founders = [
  {
    name: "Victor Gonzalez",
    responsibleFor: "Founder/Maker",
    instagram: "https://www.instagram.com/victorgonzalez96/",
    twitter: "https://twitter.com/Vic_E_Gonzalez",
    linkedin:
      "https://www.linkedin.com/in/victor-gonz%C3%A1lez-arana-97358b164/",
    description:
      "I'm a software engenieer and Pau is an attempt to solve a personal problem I used to have, It's inpired in all people I worked with, there is a little of them in this project since coding best practices to design stuff, hope you find this product useful, cheers 🙌",
    image: VictorFound,
  },
];

export default BehindPauPage;
